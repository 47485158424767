import Vue from 'vue'
import VueI18n from 'vue-i18n'

import ar from 'vuetify/lib/locale/ar'
import en from 'vuetify/lib/locale/en'
 import axios from 'axios'
 import router from './router'
Vue.use(VueI18n)

const messages = {
  en: {
    ...require('./locales/en.json'),
    $vuetify: en
  },
  ar: {
    ...require('./locales/ar.json'),
    $vuetify: ar
  }
}




const i18n = new VueI18n({
  locale: localStorage.getItem("lang") || 'ar',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'ar',
  messages,
  silentTranslationWarn: true,
  missingWarn: false
})

// if(localStorage.getItem("lang")==null ||  localStorage.getItem("lang")==undefined ||  localStorage.getItem("lang")=='undefined' ){
//   localStorage.setItem("lang",'ar')


// }
// else{

// }

router.beforeEach((to, from, next) => {
 if(localStorage.getItem("lang")==null ||  localStorage.getItem("lang")==undefined ||  localStorage.getItem("lang")=='undefined' ){

  localStorage.setItem("lang",'ar')


}
else{
  var url_string = window.location;
var url = new URL(url_string);
var lang = url.searchParams.get("lang");
localStorage.setItem("lang",lang)

 
}
 
  axios.defaults.headers.common['accept-Language'] = localStorage.getItem("lang")
  document.querySelector('html').setAttribute('lang', localStorage.getItem("lang"))

  next();
})





// console.log(i18n);

export default i18n;

