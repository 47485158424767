<template>
  <div>


    <v-toolbar flat >
      <!-- <v-toolbar-title class="dodo">الكتب </v-toolbar-title> -->
      <!-- <v-divider class="mx-2" inset vertical></v-divider> -->


      <v-layout row wrap>
        <v-flex xs12 sm4>
          <br>
          <br>
          <v-select v-model="editedItem.cat_filter_id" :items="books_types" item-text="name_section" item-value="id"
            label="اقسام الكتب" required @change="cats_filter();is_search=true;current_page=0" return-object
            persistent-hint v-on:input="cats_filter(`${editedItem.cat_filter_id.id}`)"></v-select>



        </v-flex>

      </v-layout>

      <v-spacer></v-spacer>

      <!--Uploude Mul File-->
      <v-dialog v-model="dialogWriter" persistent max-width="1300px" max-height="1300px">

        <v-form v-model="valid" ref="form">
          <v-card>

            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container grid-list-md>
                <v-layout wrap>

                  <v-flex xs12 sm6 md12>
                    <v-text-field v-model="editedItem.name" v-validate="'required'" required :rules="nameRules"
                      class="form_label" label="الاسم"></v-text-field>
                  </v-flex>



                </v-layout>




              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" flat @click="dialogWriter=false">خروج</v-btn>
              <v-btn color="blue darken-1" :loading="LoadingdialogWriter" flat @click="saveWriter">حفظ</v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-dialog>





      <v-dialog v-model="dialog" persistent max-width="1300px" max-height="1300px">
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" color="primary" dark class="dodo mb-2" @click="dialog=true">اضافة كتاب جديد</v-btn>
        </template>
        <v-form v-model="valid" ref="form">
          <v-card>

            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container grid-list-md>
                <v-layout wrap>

                  <v-flex xs12 sm6 md12>
                    <v-text-field v-model="editedItem.title" v-validate="'required'" required :rules="nameRules"
                      class="form_label" label="العنوان"></v-text-field>
                  </v-flex>


                  <v-flex xs6 sm6 md6>


                    <v-autocomplete v-model="editedItem.section_id" :items="books_types" item-text="name_section"
                      item-value="id" label="قسم الكتاب" :rules="ArtTypeRules" hide-no-data>
                    </v-autocomplete>
                  </v-flex>

                  <v-flex xs6 sm6 md4 pt-2>

                    <!-- <v-autocomplete v-model="editedItem.writer_id" :items="writer_types" item-text="name"
                      item-value="id" label="اسم الكاتب" :rules="ArtTypeRules" hide-no-data>
                    </v-autocomplete> -->
                    <!-- <select2 :options="options" v-model="editedItem.writer_id">
                      <option disabled value="0">Select one</option>
                    </select2> -->
                    <multiselect v-model="editedItem.writer" dir="rtl" track-by="id" :rules="ArtTypeRules" label="name" placeholder="بحث اسم الكاتب" open-direction="bottom" :options="writer_types" :multiple="false" :searchable="true" :loading="isLoading" :internal-search="false" :clear-on-select="true" :close-on-select="true" :options-limit="300" :max-height="600" :show-no-results="false"  @search-change="asyncFind">
                    </multiselect>
                    <!-- <pre class="language-json"><code>{{ editedItem.writer }}{{ editedItem.writer_id }}</code></pre> -->
                  </v-flex>


                  <v-flex xs6 sm2 md2  pt-4>

                    <v-btn pt-3 mt-3 @click="dialogWriter=true" color="green" style="color:#fff">
                      اضافة كاتب
                    </v-btn>

                  </v-flex>

                  <v-flex xs6 md6 pt-2 v-if="editedItem.section_id == 4">
                    <multiselect v-model="editedItem.country" dir="rtl" track-by="id" :rules="ArtTypeRules" label="name" placeholder="بحث اسم البلد" open-direction="bottom" :options="country" :multiple="false" :searchable="true" :loading="isLoading" :internal-search="false" :clear-on-select="true" :close-on-select="true" :options-limit="300" :max-height="600" :show-no-results="false"  @search-change="findCountry">
                    </multiselect>
                  </v-flex>



                  <v-flex xs12 sm6 md12>
                    <v-text-field v-model="editedItem.detective" v-validate="'required'" required :rules="nameRules"
                      class="form_label" label="المحقق"></v-text-field>
                  </v-flex>


                  <v-flex xs12 sm6 md12>
                    <v-text-field v-model="editedItem.Publishing" v-validate="'required'" required :rules="nameRules"
                      class="form_label" label="الناشر"></v-text-field>
                  </v-flex>


                  <v-flex xs12 sm6 md12>
                    <v-text-field v-model="editedItem.ed" v-validate="'required'" required :rules="nameRules"
                      class="form_label" label="الطبعة"></v-text-field>
                  </v-flex>

                  
                  <!-- <v-select 
                v-model="selectedCountry" 
                :items="countries" 
                label="اختر البلد"
                outlined
            ></v-select> -->

                  <v-flex xs12 sm6 md12 v-if="editedItem.section_id == 4">
                    <v-text-field v-model="editedItem.number_parts" v-validate="'required'" required :rules="nameRules"
                      class="form_label" label="عدد الأجزاء"></v-text-field>
                  </v-flex>


                  <v-flex xs12 sm6 md12>
                    <v-text-field v-model="editedItem.Year" v-validate="'required'" required :rules="nameRules"
                      class="form_label" label="سنة النشر"></v-text-field>
                  </v-flex>

                </v-layout>


                <v-col class="py-0" cols="12" sm="12" md="12" v-if="editedIndex == -1">
                  <p class="tl label">الكتب <span style="color:#c00">(عنوان الملف سيكون عنوان الجزء)</span> </p>
                  <vue-dropzone ref="dropzone" :useCustomSlot="true" id="dropzone" :options="dropzoneOptions"
                    @vdropzone-success="success" @vdropzone-file-added="fileAdded" @vdropzone-error="collectErrors">
                    <div class="dropzone-container">اضغط هنا لرفع الكتب</div>
                  </vue-dropzone>
                </v-col>

                <v-flex xs12 sm12 md6 lg4>
                  <template v-if="editedItem.files">
                    <v-layout v-for="(file, i) in editedItem.files">
                      <v-text-field v-model="file.file_names" v-validate="'required'" required :rules="nameRules"
                        class="form_label" label="عنوان الجزء"></v-text-field>
                      <v-icon color="#c00" aria-hidden="false" @click="part_del(i)">mdi-delete</v-icon>
                    </v-layout>
                  </template>
                </v-flex>

                <v-col class="py-0" cols="6" sm="6" md="6" v-if="editedIndex > -1">
                  <v-text-field label="ارفاق كتاب " @click='pickFile2' v-model='file_name' id="file"
                    prepend-icon='fas fa-paperclip'></v-text-field>
                  <input type="file" style="display: none" ref="file" id="file" accept="application/pdf"
                    v-on:change="onPickfile2">
                </v-col>


                <!-- <v-layout row wrap>
                  <v-flex xs6 v-for="imgs in attached_images" :key="imgs.id">
                    <div style="width:140px;height:160px">

                      <img v-bind:src="Url+'/img_art/thumb/'+imgs.img_name" style="width:100%;height:100%;">

                    </div>
                    {{imgs.id}}
                    <v-btn v-on:click="deletepic(imgs)">Deleted

                    </v-btn>
                  </v-flex>
                  <v-flex xs2>

                  </v-flex>
                </v-layout> -->







              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close">غلق</v-btn>
              <v-btn color="blue darken-1" text @click="save">حفظ</v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-dialog>

    </v-toolbar>



    <v-layout row wrap>
      <v-flex xs5 md3 sm3 pr-1 style="padding-right: 22px !important;">
          <v-text-field ref="name" v-model="search" placeholder="اسم الكتاب" required>
          </v-text-field>
      </v-flex>

      <v-flex xs1 pa-5>
          <v-btn color="green" style="color:#fff" @click="initialize();searchs=true">بحــث</v-btn>
      </v-flex>

      <v-flex xs1 pt-5 pb-5 pr-2 v-if="searchs">
          <v-btn color="blue" style="color:#fff" @click="initialize();;searchs=false">رجوع</v-btn>
      </v-flex>

    </v-layout>


    <v-data-table :headers="headers" :items="arts" class="elevation-1" :items-per-page="15" hide-default-footer
      disable-pagination>


      <template slot="no-data">
        <v-btn color="primary" @click="initialize">Reset</v-btn>


      </template>

      <template v-slot:[`item.active`]="{ item }">



        <span v-if="item.active==1">فعال</span>
        <span v-else>غير فعال </span>
      </template>

      <template v-slot:[`item.number_parts`]="{ item }">
        <span>{{ item.number_parts ? item.number_parts : 'بلا' }}</span>
      </template>

      <template v-slot:[`item.country`]="{ item }">
        <span>{{ item.country ? item.country : 'بلا' }}</span>
      </template>

      <template v-slot:[`item.actions`]="{ item }">

        <!-- <v-icon small @click="mul_img(item)" class="mr-2">
          fas fa-images
        </v-icon>
        | -->

        <v-icon small class="mr-2" @click="editItem(item)">
          تعديل
        </v-icon>
        |
        <v-icon small @click="deleteItem(item)">
          حذف
        </v-icon>



      </template>

      <v-pagination class="pagination" total-visible="20" v-model="page" color="#c7000b"
        style="position: relative; top: 20px;" circle="" :length="pageCount">
      </v-pagination>

    </v-data-table>

    <v-pagination class="pagination" total-visible="20" v-model="page" color="#c7000b"
      style="position: relative; top: 20px;" circle="" :length="pageCount">
    </v-pagination>


  </div>
</template>


<script>
  import swal from 'sweetalert2'
  // import select2 from 'select2'
  import Multiselect from 'vue-multiselect'
  import vue2Dropzone from 'vue2-dropzone'
  import 'vue2-dropzone/dist/vue2Dropzone.min.css'
  import axios from 'axios'
  export default {
    name: 'BookView',
    props: [
      'settings',
      'books_types',
    ],
    data() {
      return {
        // countries: [], 
        // selectedCountry: null, 
        searchs: false,
        isLoading: false,
        data: new FormData(),
        errors: {},
        LoadingdialogWriter:false,
        page: 1,
        pageCount: 0,
        is_search: false,
        current_page: 1,
        last_page: 0,
        percentCompleted: 0,

        nameRules: [
          (v) => !!v || 'يجب ملىء هذا الحقل',

        ],

        activeRules: [
          (v) => !!v || 'يجب ادخال حالة الاصدار',

        ],

        ArtTypeRules: [
          (v) => !!v || 'يجب ادخال قسم الاصدار',

        ],
        uploadPercentage: 0,
        form: new FormData,
        books_types: [],
        writer_types: [],
        country:[],
        attachments: [],
        attached_images: [],
        form: new FormData,
        valid: false,
        dialog: false,
        imagesUrl: '',
        imageName: '',
        loade: false,
        img_old: '',
        file_name: null,
        posts_status: [{
            id: 1,
            name: 'نشر',

          },
          {
            id: 0,
            name: 'في الانتظار',

          }
        ],
        writers: [],
        imagesName: ' ',
        search: null,
        filez: [],
        imageFile: [],
        dialogWriter: false,
        imageUrl: '',
        journal_nums: [],
        imageFile: '',
        arts: [],
        view_ats: [],
        cats: [],
        dialog_mul: false,
        headers: [


          {
            text: 'ID',
            value: 'id',
            sortable: false,
          },
          {
            text: 'ألعنوان',
            align: 'left',
            value: 'title',
            align: "right",
            sortable: false,

          },
          {
            text: 'قسم الكتاب',
            value: 'section.name_section',
            sortable: false,
          },
          {
            text: 'اسم الكاتب',
            value: 'writer.name',
            sortable: false,
          },
          {
            text: 'سنة النشر',
            value: 'Year',
            sortable: false,
          },
          {
            text: 'الطبعة',
            value: 'ed',
            sortable: false,
          },
          {
            text: 'دار النشر',
            value: 'Publishing',
            sortable: false,
          },
          {
            text: 'البلد',
            value: 'country.name',
            sortable: false,
          },
          {
            text: 'عدد الأجزاء',
            value: 'number_parts',
            sortable: false,
          },
          {
            text: 'Actions',
            value: 'actions',
            sortable: false,
            sortable: false
          }

        ],

        desserts: [],
        editedIndex: -1,
        editedItem: {
          name: '',
          email: '',
          phone: '',
          picsid: '',
          password: '',
          state_id: '',
          country: {},
        },
        defaultItem: {
          name: '',
          email: '',
          phone: '',
          password: '',

        },
        def: {
          name: 'sds',


        },

        // books: [],
        // books_title: [],
        combine: [],
        dropzoneOptions: {

          // url: 'http://127.0.0.1:8000/api/upload_pdfs',
          url: 'https://bibliography.mk.iq/api/upload_pdfs',
          thumbnailWidth: 150,
          acceptedFiles: ".pdf",
          parallelUploads: 5,
          maxNumberOfFiles: 5,
          maxFiles: 35,
          maxFilesize: 999,
          timeout: 600000,
          headers: {
            Authorizations: "Bearer " + this.$store.state.AdminInfo.token
          },
          sending: function (file, xhr, formData) {
            formData.append("_token", "{{{ csrf_token() }}}");
          },

        }
      }
    },
    components: {
      vueDropzone: vue2Dropzone,
      Multiselect
    },
    computed: {
      formTitle() {
        return this.editedIndex === -1 ? 'أضافه مقاله جديده' : 'Edit Item'
      }
    },

    watch: {

      selected: 'search by sub_cat_id',

    },
    computed: {
      selected: function () {
        return this.getMoreitems();
      }
    },
    dialog(val) {
      val || this.close()
    },


    created() {
      document.title = 'التراث الرقمي';  
      this.initialize();
      this.article_type();
      this.get_writer_types();
      this.get_countries();
      // this.start();
      // this.fetchCountries(); 
      Fire.$on('cats_fliter', () => {

        axios.get('books_types_id/' + this.editedItem.cat_filter_id.id + '?page=' + this.current_page,
            { headers: { "Content-Type": "multipart/form-data",  Accept: "application/json",  Authorizations: "Bearer " + this.$store.state.AdminInfo.token} }
        ).then(d => {
            this.arts = d.data.data
            this.last_page = d.data.last_page;
            this.pageCount = d.data.last_page;
          })


      });


    },

    methods: {
    //   fetchCountries() {
    //     axios.get('https://restcountries.com/v3.1/all') 
    //         .then(response => {
    //             this.countries = response.data.map(country => country.name.common); 
    //         })
    //         .catch(error => {
    //             console.error('Error fetching countries:', error);
    //         });
    // },

    findCountry (query) {
        if (query.length > 2) {
          this.isLoading = true;
          axios.get('countries_search/'+query).then(response => {this.isLoading = false; this.country = response.data.data;});
        }
        
      },


      asyncFind (query) {
        if (query.length > 2) {
          this.isLoading = true;
          axios.get('writers_search/'+query).then(response => {this.isLoading = false; this.writer_types = response.data.data;});
        }
        
      },
      success(file, response) {
        this.combine.push({
          'file': response.data,
          'title': file.upload.filename.split('.').slice(0, -1).join('.')
        });
      },
      collectErrors() {},
      fileAdded(file) {
        // this.books_title.push(file.upload.filename.split('.').slice(0, -1).join('.'));
        // this.image.push(file.upload.filename);
        // console.log(this.image);
      },
      part_del(i) {
        this.editedItem.files.splice(i, 1);
      },
      pickFile2() {
        this.$refs.file.click()
      },
      onPickfile2(e) {

        this.file = this.$refs.file.files[0];
        let file = e.target.files;
        this.file = file[0]
        this.file_name = file[0].name



        if (this.file_name != '') {
          let formDataFile = new FormData();
          formDataFile.append('pdf', this.file);
          axios.post('upload_pdf/' + this.editedItem.id,
            formDataFile, {
              headers: {
                'Content-Type': 'multipart/form-data',
                Accept: "application/json",
                Authorizations: "Bearer " + this.$store.state.AdminInfo.token
              }
            },
          ).then((res) => {
            // console.log(res.data);
            this.editedItem.files.push({
              'files': res.data.data,
              'book_id': this.editedItem.id,
              'file_names': file[0].name.split('.').slice(0, -1).join('.')
            });
            this.file_name = '';

          });

        }




      },
      close_mul() {

        this.uploadPercentage = 0,

          setTimeout(() => {
            this.editedItem = Object.assign({}, this.defaultItem)
            this.editedIndex = -1
          }, 300)

        this.dialog_mul = false
      },


      getMoreitems() {

        if (this.current_page <= this.last_page) {
          this.current_page = this.page;
          // if (this.is_search) {

          //   axios.get('books_sub_types_id/' + this.editedItem.cat_filter_id.id + '?page=' + this.current_page)
          //     .then(d => {
          //       this.arts = d.data.data
          //       this.last_page = d.data.last_page;
          //       this.pageCount = d.data.last_page;
          //     })
          //     .then(d => {
          //       this.arts = d.data.data
          //       this.last_page = d.data.last_page;
          //       this.pageCount = d.data.last_page;




          //     })


          // } else {

            this.initialize();
          // }

        }


      },
      getAttachmentSize() {

        this.upload_size = 0;
        this.attachments.map((item) => {
          this.upload_size += parseInt(item.size);
        });
        this.upload_size = Number((this.upload_size).toFixed(1));
        this.$forceUpdate();
      },


      prepareFields() {

        if (this.attachments.length > 0) {
          for (var i = 0; i < this.attachments.length; i++) {
            let attachment = this.attachments[i];
            this.data.append('attachments[]', attachment);
          }

        }
      },


      removeAttachment(attachment) {

        this.attachments.splice(this.attachments.indexOf(attachment), 1);

        this.getAttachmentSize();
      },




      uploadFieldChange(e) {


        var files = e.target.files || e.dataTransfer.files;
        if (!files.length)
          return;
        for (var i = files.length - 1; i >= 0; i--) {
          this.attachments.push(files[i]);
        }

        // Reset the form to avoid copying these files multiple times into this.attachments
        document.getElementById("attachments").value = [];

        // console.log(this.attachments);
      },


      resetData() {
        this.data = new FormData(); // Reset it completely
        this.attachments = [];
        this.editedItem = {};
        this.editedIndex = -1
      },
      start() {
        // console.log('Starting File Management Component');
      },




      pickFile() {
        this.$refs.image.click()
      },


      pickFiles() {
        this.$refs.images.click()

      },

      onFilesPicked(e) {

        var files = e.target.files || e.dataTransfer.files;
        if (!files.length)
          return;

        for (var i = files.length - 1; i >= 0; i--) {
          this.attachments.push(files[i]);
        }
        // console.log(this.attachments);
        document.getElementById("attachments").value = [];

      },

      onFilePicked(e) {


        const files = e.target.files


        if (files[0] !== undefined) {



          this.imageName = files[0].name
          // console.log( this.imageName );

          if (this.imageName.lastIndexOf('.') <= 0) {
            return
          }


          const fr = new FileReader()
          fr.readAsDataURL(files[0])
          fr.addEventListener('load', () => {

            this.imageUrl = fr.result
            this.imageFile = files[0]


          })
        } else {
          this.imageName = ''
          this.imageFile = ''
          this.imageUrl = ''


        }
      },



      cats_filter() {


        Fire.$emit('cats_fliter');
      },

      viewAt_filter() {
        // console.log('view at here');
        // Fire.$emit('viewAt_filter');
      },


      initialize() {



        if(this.searchs){
          axios.get("/books_search/" + this.search, {
            headers: {
              'Content-Type': 'multipart/form-data',
              Accept: "application/json",
              Authorizations: "Bearer " + this.$store.state.AdminInfo.token
              }
          })
          .then(d => {
            this.arts = d.data.data
            this.last_page = d.data.last_page;
            this.pageCount = d.data.last_page;

          })
          .catch(() => {
              this.loading = false;
          });
        }
        else{
          axios.get('books?page='+this.current_page,
              { headers: { "Content-Type": "multipart/form-data",  Accept: "application/json",  Authorizations: "Bearer " + this.$store.state.AdminInfo.token} }
          )
          .then(d => {
            this.arts = d.data.data
            this.last_page = d.data.last_page;
            this.pageCount = d.data.last_page;
          })
        }

      },



      article_type() {
        axios.get('books_types',
              { headers: { "Content-Type": "multipart/form-data",  Accept: "application/json",  Authorizations: "Bearer " + this.$store.state.AdminInfo.token} }
        ).then(response => (this.books_types = response.data));
      },


      get_writer_types() {
        axios.get('writers?page=1' , {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorizations: "Bearer " + this.$store.state.AdminInfo.token
            }
          }).then(response => (this.writer_types = response.data.data));
      },

      get_countries() {
        axios.get('countries?page=1' , {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorizations: "Bearer " + this.$store.state.AdminInfo.token
            }
          }).then(response => (this.country = response.data.data));
      },


      // view_at() {
      //   axios.get('view_ats').then(response => (this.view_ats = response.data))
      // },



      editItem(item) {




        this.editedIndex = this.arts.indexOf(item);
        this.editedItem = Object.assign({}, item);
        this.editedItem.section_id = parseInt(this.editedItem.section_id);
        this.editedItem.writer_id = parseInt(this.editedItem.writer_id);
        this.editedItem.country_id = parseInt(this.editedItem.country_id);

        this.dialog = true;
        // this.selectedCountry = this.editedItem.country;
        // console.log(item);

        //axios.get('attached_images/' + this.editedItem.id).then(response => (this.attached_images = response.data));


        if (this.imageUrl[0] == null) {

          this.img_old = this.editedItem.img;
          // this.imageUrl = 'http://alfkrya.com/img/pdf/pic/' + this.img_old;

        }
      },

      deleteItem(item) {


        const index = this.desserts.indexOf(item)
        //confirm('Are you sure you want to delete this item?') && this.desserts.splice(index, 1)


        this.$swal({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
          // Send request to the server
          if (result.value) {
            axios.delete('books/' + item.id, {
              headers: {
                'Content-Type': 'multipart/form-data',
                Accept: "application/json",
                Authorizations: "Bearer " + this.$store.state.AdminInfo.token
              },
            }).then(() => {
              this.$swal.fire('Successfully', 'done', "success");
              this.initialize()
            }).catch(() => {
              this.$swal.fire('Failed !', 'warning', "error");
            });
          }
        })



      },

      close() {
        this.dialog = false
        this.imageUrl = '';
        setTimeout(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        }, 300)
      },
      saveWriter() {
        if (this.$refs.form.validate()) {

          //save New Art Here

this.LoadingdialogWriter=true;

          axios({
              method: 'post',
              url: 'writers',

              headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                Authorizations: "Bearer " + this.$store.state.AdminInfo.token
              },
              data: {
                name: this.editedItem.name,
                videos_types_id: this.editedItem.videos_types_id,

              },


            })




            .then((res) => {



              res
              this.dialogWriter=false
                    this.LoadingdialogWriter=false
            
             





            }).catch(() => {});





          this.get_writer_types();
          //  this.close()



        }
      },
      save() {

        this.prepareFields();
        if (this.editedIndex > -1) {
          //edit here
          axios.put('books/' + this.editedItem.id, {
                title: this.editedItem.title,
                title: this.editedItem.title,
                section_id: this.editedItem.section_id,
                writer_id: this.editedItem.writer.id,
                country_id: this.editedItem.country === null ? '' : this.editedItem.country.id,
                detective: this.editedItem.detective,
                Publishing: this.editedItem.Publishing,
                ed: this.editedItem.ed,
                year: this.editedItem.Year,
                files: this.editedItem.files,
                // country: this.editedItem.country,
                number_parts: this.editedItem.number_parts,
              },


              {
                headers: {
                  "Content-Type": "application/json",
                  Accept: "application/json",
                  Authorizations: "Bearer " + this.$store.state.AdminInfo.token
                }
              }


            )


            .then(() => {


              this.$swal.fire('Successfully', 'done', "success");
              this.dialog = false;
              this.file_name = '';
              this.file = '';
              this.imageFile = '';
              this.imageName = '';
              setTimeout( () => window.location.reload(), 1000);

            }).catch((error) => {
              if (error.status === 422) {
                this.$swal.fire({
                  title: 'Error',
                  text: error.data.message,
                  icon: 'error',
                  confirmButtonText: 'Close'
                });
              }
            });
          this.initialize();
          this.resetData();
        } else {

          if (this.$refs.form.validate()) {

            //save New Art Here
            if (this.editedItem.section_id != 2 && this.combine.length < 1) {
              this.$swal({
                title: " يجب اختيار ملف الكتاب",
                text: "",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
              });
              return;
            }
            this.$swal.fire({
              title: 'Uploading ...',
              didOpen: () => {
                this.$swal.showLoading()
              }
            });

       
            axios.post('books',{
                title: this.editedItem.title,
                section_id: this.editedItem.section_id,
                writer_id: this.editedItem.writer.id,
                country_id: this.editedItem.country.id,
                detective: this.editedItem.detective,
                Publishing: this.editedItem.Publishing,
                ed: this.editedItem.ed,
                year: this.editedItem.Year,
                files: this.combine,
                // country: this.selectedCountry,
                number_parts: this.editedItem.number_parts,

              },
              {
                headers: {
                  "Content-Type": "application/json",
                  Accept: "application/json",
                  Authorizations: "Bearer " + this.$store.state.AdminInfo.token
                }
              }
              
            ).then((res) => {
              

              if (this.combine.length > 0) {
                //   let formDataFile = new FormData();
                //   formDataFile.append('pdf', this.file);
                //   axios.post('upload_pdf/'+res.data.id,
                //   formDataFile, {
                //       headers: {
                //         'Content-Type': 'multipart/form-data',
                //         Accept: "application/json",
                //         Authorizations: "Bearer " + this.$store.state.AdminInfo.token
                //       }
                //     },
                //   ).then(() => {

                this.$swal.fire('Successfully', 'done', "success");
                this.dialog = false;
                this.close();
                this.initialize();
                this.file_name = '';
                this.file = '';
                this.imageFile = '';
                this.imageName = '';

                // }
                // ).catch(() => {});

              } else {
                this.$swal.fire('Successfully', 'done', "success");
                this.dialog = false;
                this.close();
                this.initialize();
                this.file_name = '';
                this.file = '';
                this.imageFile = '';
                this.imageName = '';
              }

            }).catch((error) => {
              if (error.status === 422) {
                this.$swal.fire({
                  title: 'Error',
                  text: error.data.message,
                  icon: 'error',
                  confirmButtonText: 'Close'
                });
              }
            });





            // this.initialize();
            // this.close()



          }

        }
      }
    },



    mounted() {
      // this.$validator.localize('en', this.dictionary)
      this.initialize();

    }

  }
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.multiselect__tags {
  border:none;
  border-bottom: solid thin rgba(0, 0, 0, 0.42);
  border-radius: 0;
}
</style>